/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from './src/i18n'

export const rootWrapper = ({ element }) => (
  <>
    <I18nextProvider i18n={i18n}>
      {element}
    </I18nextProvider>
  </>
)