import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
  resources: { // 自分の用意した翻訳ファイルを任意の場所から読み込む
    en: {
      translation: require('./locales/en/translation.json')
    },
    ja: {
      translation: require('./locales/ja/translation.json')
    }
  },
  debug: process.env.NODE_ENV === "development",
  fallbackLng: 'ja', // デフォルトの言語を設定する
  interpolation: {
    escapeValue: false,
  },
})

export default i18n